var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoaded)?_c('Loading',{attrs:{"inside-container":""}}):_c('v-container',{staticClass:"d-flex"},[_c('v-col',[_c('toolbar',{attrs:{"center-items":[
                ..._vm.actionFolders.map((oneFolder) => ({
                    name: `moveTo${oneFolder.folderCode}`,
                    label: oneFolder.folderCode,
                    color: oneFolder.folderColor,
                    size: 'x-small',
                    onClick: _vm.moveTo(oneFolder.folderCode),
                })),
            ]}}),(_vm.formData)?_c('smart-form',{attrs:{"schema":_vm.formSchema},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}}):_vm._e(),(_vm.billApprove)?_c('toolbar',{scopedSlots:_vm._u([{key:"centerItems",fn:function(){return [(_vm.clickDone)?_c('btn',{attrs:{"preset":"done","size":"x-small"},on:{"click":_vm.clickDone}}):_c('t-e-button-link',{attrs:{"company-id":_vm.billApprove.companyLink.id,"accounts-entity-name":_vm.billApprove.accountsEntityName,"accounts-id":_vm.billApprove.accountsId,"size":"x-small"}})]},proxy:true}],null,false,1322022794)}):_vm._e(),_c('note-form',{attrs:{"collection-name":"billApprove","collection-id":_vm.formData.id,"relink-code":_vm.formData.relinkCode}}),(_vm.fileType)?_c('file-upload',{key:_vm.fileType,attrs:{"label":_vm.fileType,"file-type":_vm.fileType,"multiple":"","allow-clipboard":""},on:{"set-files":_vm.setFiles}}):_vm._e()],1),_c('v-col',_vm._l((_vm.files),function(fileObj){return _c('pdf-preview',{key:JSON.stringify(fileObj),attrs:{"fileObj":fileObj,"color":"#00F","width":"100%"}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }