<template>
    <span class="d-flex align-center col-12 pa-0">
        <text-field v-model="noteText" label="Add Note" />
        <btn preset="add" @click="addNote" />
    </span>
</template>
<script>
import api from '@/api';

export default {
    name: 'AddNote',
    props: {
        collectionName: String,
        collectionId: String,
        relinkCode: String,
        noteType: String,
        noteTypeList: Array,
    },
    data: () => ({
        noteText: '',
        useNoteType: '',
    }),
    methods: {
        async addNote() {
            console.log('Add Note');
            const result = await api.post(this.$store, `note`, {
                collectionName: this.collectionName,
                collectionId: this.collectionId,
                relinkCode: this.relinkCode,
                noteType: this.useNoteType,
                noteText: this.noteText,
            });
            console.log('addNote ', result.data)
            this.$emit('change', result.data);
            this.noteText = '';
        },
    },
    mounted() {
        this.useNoteType = this.noteType || 'Manual';
    },
};
</script>
