<template>
    <Loading v-if="!isLoaded" inside-container />
    <v-container v-else class="d-flex">
        <v-col>
            <toolbar
                :center-items="[
                    ...actionFolders.map((oneFolder) => ({
                        name: `moveTo${oneFolder.folderCode}`,
                        label: oneFolder.folderCode,
                        color: oneFolder.folderColor,
                        size: 'x-small',
                        onClick: moveTo(oneFolder.folderCode),
                    })),
                ]"
            />

            <smart-form v-if="formData" :schema="formSchema" v-model="formData" />

            <toolbar v-if="billApprove">
                <template v-slot:centerItems>
                    <btn v-if="clickDone" preset="done" @click="clickDone" size="x-small" />
                    <t-e-button-link
                        v-else
                        :company-id="billApprove.companyLink.id"
                        :accounts-entity-name="billApprove.accountsEntityName"
                        :accounts-id="billApprove.accountsId"
                        size="x-small"
                    />
                </template>
            </toolbar>

            <note-form collection-name="billApprove" :collection-id="formData.id" :relink-code="formData.relinkCode" />

            <file-upload
                v-if="fileType"
                :label="fileType"
                :file-type="fileType"
                :key="fileType"
                @set-files="setFiles"
                multiple
                allow-clipboard
            />
        </v-col>
        <v-col>
            <pdf-preview
                v-for="fileObj in files"
                :fileObj="fileObj"
                :key="JSON.stringify(fileObj)"
                color="#00F"
                width="100%"
            />
        </v-col>
    </v-container>
</template>
<script>
import api from '@/api';

import FileUpload from '@/components/form/fields/fileUpload/FileUploadField';
import PdfPreview from '@/components/common/PdfPreview';
import Loading from '@/components/app/Loading';
import NoteForm from '@/components/common/note/NoteForm';
import TEButtonLink from '../common/TEButtonLink';

const formSchema = {
    vendorCode: {
        component: 'text-field',
        label: 'Vendor',
        cols: 4,
        readOnly: true,
    },
    documentReference: {
        component: 'text-field',
        label: 'Reference',
        cols: 4,
        readOnly: true,
    },
    documentTotal: {
        component: 'currency-field',
        label: 'Total',
        cols: 4,
        readOnly: true,
        currency: '$',
    },
    documentDate: {
        component: 'date-field',
        label: 'Date',
        cols: 4,
        readOnly: true,
    },
    dueDate: {
        component: 'date-field',
        label: 'Due',
        cols: 8,
        readOnly: true,
    },
    'approved.approveDate': {
        component: 'date-field',
        label: 'Approve Date',
        cols: 4,
        readOnly: true,
    },
    'approved.userLink.text': {
        component: 'text-field',
        label: 'Approved By',
        cols: 4,
        readOnly: true,
    },
};

export default {
    name: 'BillDetail',
    components: { FileUpload, Loading, PdfPreview, NoteForm, TEButtonLink },
    props: {
        billApprove: Object,
        folders: Array,
        clickDone: Function,
    },
    data() {
        return {
            result: null,
            files: null,
            formSchema,
            formData: null,
            isLoaded: false,
            fileType: null,
            fileObjs: [],
            sourceEntityType: null,
            sourceEntityValue: null,
        };
    },
    methods: {
        async getDetail() {
            try {
                const result = await api.get(this.$store, `bill/approve/${this.billApprove.id}`);
                console.log('billdetrail getdetail1', { result });
                this.result = result.data;
                console.log('billdetrail getdetail2', this.result);
                this.formData = {
                    id: this.result.billApprove.id,
                    ...this.result.billApprove.billData,
                    approved: this.result.billApprove.approved,
                    relinkCode: this.result.billApprove.relinkCode,
                };
                const { billBalance, attachables } = this.result;

                this.files = [...attachables];
                this.sourceEntityType = 'dochead';
                this.sourceEntityValue = billBalance.accountsDocumentId;
                this.fileType =
                    billBalance && `2Easy ${this.sourceEntityType} ${this.sourceEntityValue}`;
                // this.files = [this.formData.file];
                // previewUrl: "http://192.168.41.8/documents/live/thumbs/financial/2022/AP/INVOICE-APINV-ARAFIREABBYY.2022-05-06.51778.pdf.lg.png"

                console.log('formData', this.formData);
            } catch (e) {
                console.error(e.message, e.stack);
            }
        },
        async log(e) {
            console.log('logging', e);
        },
        updatedBillApprove(billApprove) {
            this.$emit('change', billApprove);
        },
        moveTo(folderCode) {
            return async () => {
                console.log('moveTo', folderCode);
                const result = await api.post(this.$store, 'bill/approve', {
                    billApproveId: this.billApprove.id,
                    action: 'setFolder',
                    data: {
                        folderCode,
                    },
                });
                console.log('moveto Result', result.data);
                if (result.data && result.data.success) {
                    this.updatedBillApprove(result.data.billApprove);
                }
            };
        },
        viewDocument() {
            const accountsDocumentId = this.billApprove && this.billApprove.accountsDocumentId;
            const url = `http://bambam.su.local/cgi-bin/sutg.sh/WService=sutglive/prop/doc2.p?tableid=${accountsDocumentId}`;
            console.log('view document clicked', url);
            window.open(url, '_blank');
        },
        async setFiles(fileObjs) {
            console.log({ fileObjs });
            this.fileObjs = fileObjs;
            const allFileIds = fileObjs.map((oneFileObj) => oneFileObj.file.id);
            if (this.fileObjs && this.fileObjs.length) {
                await Promise.all(
                    this.fileObjs.map((oneFileObj) =>
                        api.post(this.$store, 'file/uploadfiletote', {
                            file: oneFileObj.file,
                            sourceEntityType: this.sourceEntityType,
                            sourceEntityValue: this.sourceEntityValue,
                            allFileIds,
                        }),
                    ),
                );
            } else {
                console.log('about to hit upload te');
                await api.post(this.$store, 'file/uploadfiletote', {
                    sourceEntityType: this.sourceEntityType,
                    sourceEntityValue: this.sourceEntityValue,
                    allFileIds,
                });
                console.log('returned upload te');
            }
            console.log('reloading detail');
            await this.getDetail();
        },
    },
    computed: {
        actionFolders() {
            return this.folders.filter(
                (oneFolder) => !['New', 'All'].includes(oneFolder.folderCode),
            );
        },
    },
    async created() {
        console.log('folders', { folders: this.folders });
        await this.getDetail();
        console.log('billdetail created finished');
        this.isLoaded = true;
    },
};
</script>
