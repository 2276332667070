<template>
    <btn label="2Easy" :size="size" @click="viewDocument" />
</template>
<script>
import api from '@/api';

export default {
    name: 'TEButtonLink',
    props: {
        companyId: String,
        accountsEntityName: String,
        accountsId: String,
        size: String,
    },
    data() {
        return {};
    },
    methods: {
        async viewDocument() {
            const result = await api.post(this.$store, 'bill/balance', {
                companyId: this.companyId,
                accountsEntityName: this.accountsEntityName,
                accountsId: this.accountsId,
            });

            if (result.data && result.data.accountsDocumentId) {
                const url = `http://bambam.su.local/cgi-bin/sutg.sh/WService=sutglive/prop/doc2.p?tableid=${result.data.accountsDocumentId}`;
                console.log('view document clicked', url);
                window.open(url, '_blank');
            }
        },
    },
};
</script>
