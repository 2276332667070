<template>
    <div style="background-color: white;">
        <a v-if="fileObj && fileObj.downloadUrl" :href="fileObj.downloadUrl" target="_blank">
            <te-image v-if="fileObj && fileObj.previewUrl" :url="fileObj.previewUrl" v-bind="$attrs" :on="$listeners" />
        </a>
        <img v-else-if="fileObj && fileObj.previewUrl" :src="fileObj.previewUrl" v-bind="$attrs" :on="$listeners" />
    </div>
</template>

<script>
import TeImage from '@/components/common/TEImage';
export default {
    name: 'PdfPreview',
    components: { TeImage },
    data() {
        return {};
    },
    props: {
        fileObj: Object,
    },
};
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
    background: #282828aa;
    box-shadow: none;
    border-radius: 6px;
    width: auto;
    color: whitesmoke;
    z-index: 1000;
}
</style>
