<template>
        <v-dialog v-if="notes.length" v-model="isShowDialog" class="" :max-width="1100">
            <template v-slot:activator="{ on }">
                <span class="d-flex">
                    <btn
                        size="small"
                        label="More"
                        color="primary"
                        @click="() => (isShowDialog = !isShowDialog)"
                    />
                    <v-badge color="error" overlap :content="notes.length || '0'" />
                </span>
            </template>
            <v-container class="ma-0 pa-0 " xstyle="background-color: yellowgreen">
                <v-card>
                    <v-card-title>
                        <span class="headline">Notes List</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <smart-table
                                :headers="headers"
                                :items="notes"
                                dense
                                class="elevation-0"
                                :itemsPerPage="20"
                                itemKey="id"
                                :key="JSON.stringify(notes)"
                            >
                                <template v-slot:item.createdAt="{ item }">
                                    {{ formatDate(item.createdAt) }}
                                </template>
                            </smart-table>
                        </v-container>
                    </v-card-text>
                </v-card></v-container
            >
        </v-dialog>
</template>
<script>
import dateUtil from '@/../common/utils/date';

export default {
    name: 'NoteDialog',
    props: {
        notes: Array,
    },
    data: () => ({
        noteText: '',
        useNoteType: '',
        headers: [
            {
                text: 'User',
                align: 'left',
                sortable: true,
                value: 'userLink.text',
                width: 100,
            },
            {
                text: 'Date',
                align: 'center',
                sortable: false,
                value: 'createdAt',
                width: 100,
            },
            {
                text: 'Note',
                align: 'left',
                sortable: false,
                value: 'noteText',
                width: 800,
            },
        ],
        isShowDialog: false,
    }),
    methods: {
        formatDate(dateUTCString) {
            return dateUtil.ymdToDmy(dateUtil.dateToString(new Date(dateUTCString)));
        },
    },
    mounted() {
        this.useNoteType = this.noteType || 'Manual';
    },
};
</script>
