<template>
    <img v-if="dataUri" :src="dataUri" v-bind="$attrs" />
</template>

<script>
import axios from 'axios';

import api from '@/api';
export default {
    props: {
        url: String,
    },
    data() {
        return {
            dataUri: null,
        };
    },
    methods: {
        async getImageDownloadToken() {
            const result = await api.post(this.$store, 'teimage', {
                url: this.url,
            });
            return {
                token: result.data.token,
                url: result.data.url,
            };
        },
        async downloadImage(url, token) {
            const result = await axios.post(
                url,
                {},
                {
                    'Content-Type': 'image/png',
                    headers: {
                        token,
                    },
                },
            );
            console.log('TEImage result',result)
            this.dataUri = `data:Image/png;base64,${result.data}`;
        },
        async getTokenAndDownloadImage() {
            const { url, token } = await this.getImageDownloadToken();
            await this.downloadImage(url, token);
        },
    },
    async created() {
        await this.getTokenAndDownloadImage();
    },
};
</script>
