<template>
    <div fluid class="mx-7 my-4 constrain-width">
        <v-row class="pt-6 pb-2 title">
            {{ title }}
        </v-row>
        <v-row>
            <add-note
                :collection-name="collectionName"
                :collection-id="collectionId"
                :relink-code="relinkCode"
                @change="updatedNotes"
            />
        </v-row>
        <v-row>
            <v-col>
                <v-container
                    class="ma-0 pa-0"
                    v-for="oneNotePreview in notePreviews"
                    :key="oneNotePreview.id"
                >
                    <v-row class="ma-0 pa-0">
                        <v-col class="ma-0 pa-0">
                            <p class="ma-0">
                                {{ oneNotePreview.noteText }}
                            </p>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0 py-0 grey--text text-sm-caption">
                        {{ oneNotePreview.userLink.text }}
                        {{ getDistance(oneNotePreview.createdAt) }}
                    </v-row>
                </v-container>
            </v-col>
            <v-col class="shrink">
                <note-dialog :notes="notes" />
            </v-col>
        </v-row>
        <note-field-dialog :noteData="notes" :key="JSON.stringify(notes)" @change="updatedNotes" />
    </div>
</template>

<script>
import api from '@/api';
import NoteFieldDialog from '@/components/form/fields/NoteFieldDialog';
import AddNote from '@/components/common/note/AddNote';
import dateUtil from '@/../common/utils/date';
import NoteDialog from '@/components/common/note/NoteDialog';

export default {
    name: 'NoteForm',
    components: { NoteDialog, AddNote, NoteFieldDialog },
    props: {
        collectionName: String,
        collectionId: String,
        numberOfNotes: { type: Number, default: 3 },
        relinkCode: String,
    },
    data: () => ({
        title: 'Notes',
        notePreviews: [],
        notes: [],
        newNote: '',
    }),
    methods: {
        generateNotePreviews() {
            this.notePreviews = this.notes.reverse().slice(0, this.numberOfNotes);
            // .map(oneNote => `${dateUtil.getDistance(oneNote.createdAt)} ${oneNote.user.userCode}: ${oneNote.noteText}`);
        },
        async loadListData() {
            try {
                const result = await api.get(
                    this.$store,
                    `note/${this.collectionName}/${this.collectionId}`,
                );

                if (result.data.notes) {
                    this.notes = result.data.notes;
                    this.generateNotePreviews();
                }
            } catch (e) {
                console.log('error loading note list', e.message);
            }
        },
        getDistance(dateStringValue) {
            return dateUtil.getDistance(dateStringValue);
        },
        updatedNotes(event) {
            console.log('updatedNotes', event);
            if (event.notes) {
                this.notes = [...event.notes];
                this.generateNotePreviews();
            }
            this.$emit('change', event);
        },
    },
    async mounted() {
        console.log('mounted');
        await this.loadListData();
    },
};
</script>
